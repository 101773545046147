<template>
  <section class="latest-posts">
    <bxs-layout>
      <div class="title-group">
        <h3 class="title" ref="title">We.Projects</h3>
        <h4 class="subtitle" ref="subtitle">Scopri i nostri progetti ufficiali We.Ensemble</h4>
      </div>

      <ul v-if="posts" class="project-list">
        <li v-for="(post, i) in posts" :key="i" :class="`post-${i}`">
          <router-link to="/crazy-week-project">
            <bxs-figure :src="post.yoast_head_json.og_image[0].url" width="525" height="500" />
          </router-link>
          <div class="post-meta">
            <div class="post-data">
              <!--
              <router-link :to="post.link">
                <h4 class="post-title">{{ post.title.rendered }}</h4>
              </router-link>
              -->
              <router-link to="/crazy-week-project">
                <h4 class="post-title">{{ post.title.rendered }}</h4>
              </router-link>
              <div class="categories">
                <span>Live</span>
                <span>/</span>
                <span>Recording</span>
              </div>
            </div>
            <div class="post-btn">
              <bxs-btn to="/crazy-week-project" class="arrow-btn">
                <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 19L9 10L1 1" stroke="#024345" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </bxs-btn>
            </div>
          </div>
        </li>
      </ul>
    </bxs-layout>
  </section>
</template>

<script>
import { gsap } from 'gsap'
import { mapState } from 'vuex'
export default {
    name: 'bxs-latest-posts',
    computed: {
      ...mapState({
        posts: state => state.latest_news
      })
    },
    mounted() {
      this.startAnimation()
    },

    watch: {
      isPageTransitionEnded(value) {
        if (value === false) {
          this.startAnimation()
        }
      }
    },

    methods: {
      startAnimation() {
        const titleMotion = gsap.timeline({})
        titleMotion.delay(0.2)

        titleMotion
          .fromTo(this.$refs.title, {
              opacity: '0',
              y: 50
          }, {
              opacity: '1',
              y: 0,
              duration: 0.8,
              ease: 'power3.easeOut'
          })
      }
    }
}

</script>
<style lang="scss">
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mixins.scss';

.title-group{
  margin-bottom: 80px;
  text-align: center;

  h3{
    font-size: 50px;
    line-height: 63px;
    letter-spacing: .03em;
    color: var(--color-black);
  }

  h4{
    color: #4D5053;
    font-size: 22px;
    font-weight: 400;
    opacity: .8;
    margin-top: -5px;
  }
}

.post-meta{
  margin-top: 20px;
  display: flex;
  align-content: space-between;

  .post-data{
    * {
      text-decoration: none !important;
    }
  }

  .post-title{
    color: var(--color-black);
    letter-spacing: .03em;
    font-size: 26px;
    line-height: 30px;
    text-decoration: none !important;
    @include Transition;

    &:hover{
      color: var(--color-primary);
    }
  }

  .post-btn{
    margin-left: auto;
  }

  .categories{
    margin-top: -14px;

    span{
      margin-right: 5px;
      font-size: 21px;
      color: #4D5053;
    }
  }

}

.project-list{
  display: flex;
  flex-wrap: wrap;
  gap: 6%;

  li{
    flex: 1 0 44%;
    margin-bottom: 40px;

    img{
      @include Transition;
    }

    a{
      &:hover{
        img{
          transform: scale(1.05);
        }
      }
    }

    &.post-0 figure{
    border-radius: 0 80px 0 0;
    }

    &.post-1 figure{
    border-radius: 80px 0 0 0;
    }

    &.post-2 figure{
    border-radius: 0 0 80px 0;
    }

    &.post-3 figure{
      border-radius: 0 0 0 80px;
    }
  }
}
</style>
