<template>
  <div class="bxs-preloader">
    <div class="bxs-preloader--container">
      <slot
      :progress="progress"
      :progress-scale="progress_scale"></slot>
    </div>
  </div>
</template>

<script>
import { eachSeries } from 'async'

export default {
  name: 'bxs_preloader',
  props: {
    resources: {
      type: Array,
      required: false,
      default: () => []
    },
    fixedValues: {
      type: Boolean,
      required: false,
      default: true
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: [
    'complete',
    'resource-loaded'
  ],
  computed: {
    progress () {
      if (this.resources.length === 0) return 0
      const progress = (100 / this.resources.length) * this.resolved
      return this.fixedValues ? Math.round(progress) : progress.toFixed(2)
    },
    progress_scale () {
      if (this.resources.length === 0) return 0
      return 1 / (this.resources.length / this.resolved)
    }
  },
  data () {
    return {
      promises: [],
      resolved: 0
    }
  },
  mounted () {
    if (this.autoplay) this.$nextTick(this.start)
  },
  watch: {
    resolved (newVal) {
      if (newVal === this.promises.length) {
        this.complete()
      }
    }
  },
  methods: {
    start (cb) {
      if (!this.resources.length) {
        return new Promise((resolve) => resolve())
      }

      return eachSeries(this.resources, async (item) => {
        let resource = null

        if (item.type === 'img') {
          function imgCall () {
            return new Promise((resolve, reject) => {
              const image = new Image()
              image.src = item.url

              if (!image.complete) {
                image.onload = () => resolve(image)
                image.onerror = reject
              } else {
                resolve(image)
              }
            })
          }

          resource = await imgCall()
        } else if (item.type === 'store_fetch') {
          resource = await this.$store.dispatch(item.promise)
        } else if (item.type === 'fetch') {
          resource = await fetch(item.url)
        }

        this.resolved += 1
        this.$emit('resource-loaded', { id: item.id, resource: resource })

        // this.$emit('resource-load-error', err)
        // this.resolved += 1
      }, cb)
    },
    complete () {
      setTimeout(() => {
        this.$emit('complete')
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.bxs-preloader {
  position: fixed;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  top: 0;
  left: 0;
  z-index: var(--layer-preloader);

  display: flex;
  justify-content: center;
  align-items: center;

  &--container {
    position: relative;
    width: 100%;
    max-width: 768px;
    margin: 0 auto;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  &--progress {
    position: relative;
    width: 100%;
    max-width: 50%;
    margin: 0 auto;
    height: 2px;
    border-radius: 2px;
    background-color: rgb(220, 220, 220);
    margin-top: 2rem;

    > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform-origin: left bottom;
      transform: scaleX(0);
      transition: transform 0.5s ease-out;
      background-color: rgb(0, 0, 0);
    }
  }
}
</style>
