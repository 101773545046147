<template>
    <div class="page404-error">
      <bxs-layout>
        <bxs-row>
          <bxs-col>
            <h1>404</h1>
            <p>Ci scusiamo, la pagina che stai cercando non è raggiungibile.</p>
            <bxs-btn
              append-icon="arrow-right"
              to="/"
              class="inverted"
              >
                Torna alla Home
            </bxs-btn>
          </bxs-col>
        </bxs-row>
      </bxs-layout>
    </div>
</template>

<script>
export default {
    name: 'error_404'
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mixins.scss';

.page404-error{
  min-height: 100vh;
  background:transparent url('@/assets/img/page404.jpg') no-repeat right top;
  background-size: contain;

  h1{
    font-size: 10vw;
    color: var(--color-primary);
    padding-top: 20vh;
  }

  p{
    margin-bottom: 40px;
    max-width: 400px;
    font-size: 24px;
    line-height: 30px;
    opacity: .7;
  }
}
</style>
