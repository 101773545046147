import { createApp, reactive } from 'vue'
import App from './App.vue'
import './registerServiceWorker'

// internal libs
import router from './router'
import store from './store'
import createComponents from './components'
import createDirectives from './directives'
import filters from './filters'

// external libs

import Eventer from './assets/libs/Eventer'
import Wp from './assets/libs/Wp'
import { useToast } from 'vue-toastification'
import { createHead, VueHeadMixin } from '@unhead/vue'

const app = createApp(App)
const head = createHead()
const wp = new Wp()
app.config.globalProperties.$wp = reactive(wp)
app.config.globalProperties.$filters = filters
app.config.globalProperties.$toast = useToast()
app.config.globalProperties.$eventHub = new Eventer()

app.mixin(VueHeadMixin)
createDirectives(app)
createComponents(app)

app
    .use(head)
    .use(store)
    .use(router)
    // .mount('#app')

/*
    mod 2 mount after router is ready
*/
router.isReady().then(() => {
    app.mount('#app', true)
})

if (process.env.NODE_ENV === 'production') {
    console.log = function () {}
}
