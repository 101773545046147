import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

import Error404 from '../views/404.vue'
import Home from '../views/Home.vue'
import Page from '../views/Page.vue'

import About from '@/views/About.vue'
import Services from '@/views/Services.vue'
import Projects from '@/views/Projects.vue'
import Contacts from '@/views/Contacts.vue'
import SingleProject from '@/views/SingleProject.vue'

const routes = [
  //
  {
    path: '/',
    name: 'homepage',
    component: Home,
    meta: {
      is_page: true
    }
  },
  {
    path: '/chi-siamo',
    name: 'about',
    component: About,
    meta: {
      is_page: true
    }
  },
  {
    path: '/servizi',
    name: 'services',
    component: Services,
    meta: {
      is_page: true
    }
  },
  {
    path: '/contatti',
    name: 'contacts',
    component: Contacts,
    meta: {
      is_page: true
    }
  },
  {
    path: '/progetti',
    name: 'projects',
    component: Projects,
    meta: {
      is_page: true
    }
  },
  {
    // path: '/projects/:slug',
    path: '/crazy-week-project',
    name: 'single-project',
    component: SingleProject,
    meta: {
      is_page: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    component: Page,
    meta: {
      is_page: true
    }
  },
  {
    path: '/errors/404',
    name: 'error_404',
    component: Error404
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
  /*
  scrollBehavior(to, from, savedPosition) {
    return { left: 0, top: 499 }
  }
  */
})

router.beforeEach((to, from, next) => {
  // console.log('Router External - beforeEach()', to.path, to.name)

  store.commit('setPageEnter', to.name)
  store.commit('setPageLeave', from && from.name ? from.name : null)
  return next()
})

export default router
