<template>
    <div class="page--view">
      <section>
          <bxs-layout>
            <h1>I'm {{ $route.fullPath }} page</h1>
          </bxs-layout>
      </section>

      <section
      v-for="v in 4"
      :key="v">
        <bxs-layout>
          <h1>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Pariatur cum quidem eveniet molestias culpa maiores dolorum ipsam? Eveniet, praesentium dignissimos reiciendis eligendi officia asperiores alias non! Laboriosam porro eligendi optio.</h1>
        </bxs-layout>
      </section>

        <!-- render layout da wp -->
        <!-- <component :is="var_che_arriva_da_dati_pagina_wp">
            <div v-htm="dati_che_arrivano_da_pagina"></div>
        </component>   -->

        <!-- render componenti da wp -->
        <!-- <component
        v-for="comp in data.page.components"
        :key="comp.id"
        :is="comp.key">
            <div v-htm="comp.comp_wyswyg"></div>
        </component> -->

        <!-- render layout con dentro componenti da wp -->
        <!-- <component :is="var_che_arriva_da_dati_pagina_wp">
            <div v-htm="comp..data_page_wyswyg"></div>
            <component
            v-for="comp in data.page.components"
            :key="comp.id"
            :is="comp.key" />
        </component> -->

        <!-- render layout con dentro componenti da wp con innesto di props nei componenti -->
        <!-- <component :is="var_che_arriva_da_dati_pagina_wp">
            <div v-htm="comp.data_page_wyswyg"></div>
            <component
            v-for="comp in data.page.components"
            :key="comp.id"
            :is="comp.key"
            v-bind="comp.props" />
        </component> -->
    </div>
</template>

<script>
export default {
    name: 'page'
}
</script>
