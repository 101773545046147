<template>
  <div class="about">
    <bxs-page-heading
      title="About Us"
      subtitle="La musica perfetta per il vostro evento."
      src="https://staging.weplaycoop.it/wp-content/uploads/2024/09/about-us.png"
    >
    </bxs-page-heading>
    <section class="about-focus" style="margin-top: 100px">
      <bxs-layout>
        <bxs-row style="margin-bottom: 80px">
          <bxs-col cols="7">
            <div class="text-content">
              <h2>We.Mission</h2>
              <p>
                <strong>Vogliamo semplificare il lavoro di chiunque abbia il bisogno di musica nella propria  realtà e dei musicisti.</strong>
              </p>
              <p>
                Il progetto è basato su un modello aziendale coerente con la qualità del prodotto e che possa soddisfare le richieste di una vasta gamma di clienti, agevolando i processi lavorativi, burocratici e comunicativi.
              </p>
            </div>
          </bxs-col>
          <bxs-col cols="5">
            <bxs-figure class="main-focus-img" src="https://staging.weplaycoop.it/wp-content/uploads/2024/09/about-1.jpg" alt="I migliori partner musicali in Italia." height="435"></bxs-figure>
          </bxs-col>
        </bxs-row>
        <bxs-row style="margin-bottom: 80px">
          <bxs-col cols="5">
            <bxs-figure class="main-focus-img" src="https://staging.weplaycoop.it/wp-content/uploads/2024/09/about-2.jpg" alt="I migliori partner musicali in Italia." height="435"></bxs-figure>
          </bxs-col>
          <bxs-col cols="7">
            <div class="text-content text-content-left">
              <h2>We.Mission</h2>
              <p>
                <strong>Produrre musica moderna e pop per qualsiasi tipo di evento con strumenti classici.</strong>
              </p>
              <p>
                A distanza di 10 anni dall’inizio del progetto, We.Ensemble si presenta come una realtà affiatata e professionale, in grado di produrre musica di qualità  sopraffina.
              </p>
            </div>
          </bxs-col>
        </bxs-row>
      </bxs-layout>
    </section>

    <section class="we-people">
      <div class="people-titlegroup">
        <h2>We.People</h2>
        <p>Il nostro progetto è basato sull’esperienza pluri decennale di musicisti e esperti del settore musicale che lavorano in questo campo quotidianamente. </p>
      </div>
      <ul class="people-grid">
        <li>
          <div class="figure-wrapper">
            <bxs-figure src="https://staging.weplaycoop.it/wp-content/uploads/2024/09/giulia-sandoli.png" height="400" width="400"></bxs-figure>
          </div>
          <div class="people-meta">
            <h3>Giulia Sandoli</h3>
            <div>Direttrice Generale</div>
            <a href="#" target="_blank"><bxs-icon name="li"></bxs-icon></a>
          </div>
        </li>
        <li>
          <div class="figure-wrapper">
            <bxs-figure src="https://staging.weplaycoop.it/wp-content/uploads/2024/09/susanna-tognella.png" height="400" width="400"></bxs-figure>
          </div>
          <div class="people-meta">
            <h3>Susanna Tognella</h3>
            <div>Responsabile area finanziaria</div>
            <a href="#" target="_blank"><bxs-icon name="li"></bxs-icon></a>
          </div>
        </li>
        <li>
          <div class="figure-wrapper">
            <bxs-figure src="https://staging.weplaycoop.it/wp-content/uploads/2024/09/martina-motta.png" height="400" width="400"></bxs-figure>
          </div>
          <div class="people-meta">
            <h3>Martina Motta</h3>
            <div>Responsabile comunicazione</div>
            <a href="#" target="_blank"><bxs-icon name="li"></bxs-icon></a>
          </div>
        </li>
        <li>
          <div class="figure-wrapper">
            <bxs-figure src="https://staging.weplaycoop.it/wp-content/uploads/2024/09/luca-rapazzini.png" height="400" width="400"></bxs-figure>
          </div>
          <div class="people-meta">
            <h3>Luca Rapazzini</h3>
            <div>Direttore Artistico</div>
            <a href="#" target="_blank"><bxs-icon name="li"></bxs-icon></a>
          </div>
        </li>
        <li>
          <div class="figure-wrapper">
            <bxs-figure src="https://staging.weplaycoop.it/wp-content/uploads/2024/09/sara-marazzi.png" height="400" width="400"></bxs-figure>
          </div>
          <div class="people-meta">
            <h3>Sara Marazzi</h3>
            <div>Responsabile Marketing</div>
            <a href="#" target="_blank"><bxs-icon name="li"></bxs-icon></a>
          </div>
        </li>
      </ul>
    </section>

    <bxs-we-numbers/>

  </div>
</template>

<script>

export default {
  name: 'about',
  mounted () {
    this.$nextTick(this.start)
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mixins.scss';

h2{
  font-size: 48px;
  line-height: 60px;
  color: var(--color-black);
  letter-spacing: .03em;
}
.text-content{
  margin-top: 70px;
  padding-right: 40px;

  &.text-content-left{
    padding-left: 50px;
  }
}
.main-focus-img{
  border-radius: 50px !important;
}

p{
  font-size: 20px;
  line-height: 30px;
  margin-top: 20px;

  strong{
    font-weight: 600;
  }
}

.we-people{
  background: var(--color-secondary);
  text-align: center;
  padding-top: 80px;
  padding-bottom: 20px;
  margin-bottom: 130px;

  .people-titlegroup{
    padding-bottom: 80px;
    p{
      font-size: 24px;
      line-height: 38px;
      opacity: .7;
      max-width: 824px;
      margin: 0 auto;;
    }
  }

  .people-grid{
    display: flex;
    flex-wrap: wrap;

    li{
      flex: 1 1 33.33%;
      margin-bottom: 100px;
    }

    .figure-wrapper{
      display: inline-block;
      position: relative;

      &::after{
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
        background: white;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        transform: translate(0, 50px);
      }
    }

    figure{
      background: transparent;
      overflow: visible;
      z-index: 10;
    }

    .people-meta{
      margin-top: 80px;

      h3{
        font-size: 22px;
        line-height: 22px;
        color: var(--color-black);
      }

      div{
        font-size: 20px;
        opacity: .7;
        margin-top: -10px;
        margin-bottom: 20px;
      }

      a{
        @include Transition;
        color: var(--color-black);
        opacity: .7;
        transform: scale(1.4);

        &:hover{
          opacity: 1;
        }
      }
    }
  }
}
</style>
