<template>
  <section class="our-customers">
    <bxs-layout class="bxs-layout-fluid">
      <bxs-row>
        <div class="customers-intro">
          <h3 ref="title">Oltre 10 anni di esperienza, oltre 200 progetti musicali</h3>
          <p ref="subtitle">Il nostro ensemble ha una profonda esperienza musicale in ambito lavorativo. Nel corso degli anni abbiamo avuto il piacere di collaborare con molti dei brand più famosi al mondo.</p>
        </div>
      </bxs-row>
      <bxs-row>
        <ul class="brands">
          <li><img src="/img/logos/frame01.png" alt="Logo1"></li>
          <li><img src="/img/logos/frame02.png" alt="Logo2"></li>
          <li><img src="/img/logos/frame03.png" alt="Logo3"></li>
          <li><img src="/img/logos/frame04.png" alt="Logo4"></li>

          <li><img src="/img/logos/frame01.png" alt="Logo1"></li>
          <li><img src="/img/logos/frame02.png" alt="Logo2"></li>
          <li><img src="/img/logos/frame03.png" alt="Logo3"></li>
          <li><img src="/img/logos/frame04.png" alt="Logo4"></li>
        </ul>
      </bxs-row>
    </bxs-layout>
  </section>
</template>

<script>
import { gsap } from 'gsap'
export default {
    name: 'bxs-our-customers',

    mounted() {
      this.startAnimation()
    },

    watch: {
      isPageTransitionEnded(value) {
        if (value === false) {
          this.startAnimation()
        }
      }
    },

    methods: {
      startAnimation() {
        const titleMotion = gsap.timeline({})
        titleMotion.delay(0.2)

        titleMotion
          .fromTo(this.$refs.title, {
              opacity: '0',
              y: 50
          }, {
              opacity: '1',
              y: 0,
              duration: 0.8,
              ease: 'power3.easeOut'
          })
      }
    }
}

</script>
<style scoped lang="scss">
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mixins.scss';

.our-customers{
  background-color: var(--color-secondary);
  padding: 100px 0 80px;

  .customers-intro{
    max-width: 1030px;
    margin: 0 auto;
    text-align: center;

    h3{
      font-weight: 600;
      font-size: 40px;
    }

    p{
      font-size: 20px;
      line-height: 32px;
      padding: 0 40px;
      margin-bottom: 40px;
    }
  }

  .brands{
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }
}
</style>
