import { gsap } from 'gsap'

gsap.config({
    autoSleep: 120,
    force3D: 'auto'
    // nullTargetWarn: false,
    // trialWarn: false
})

const transitions = new Map()

const fallback = (el, direction, resolve) => {
    const is_in = direction === 'in'

    const tl = gsap.timeline({
        onComplete: () => {
            if (!is_in) {
              window.scrollTo(0, 0)
            }
            resolve(tl)
        }
    })

    tl
        .addLabel('start')
        .fromTo(el, {
            autoAlpha: is_in ? 0 : 1,
            y: is_in ? 80 : 0
        }, {
            autoAlpha: is_in ? 1 : 0,
            duration: 0.7,
            y: is_in ? 0 : 80,
            ease: 'expoScale(1,2,power1.in)"'
        })
}

const preloader = (el, direction, resolve) => {
    const is_in = direction === 'in'
    // const title = el.querySelector('h1')
    // const progress_line = el.querySelector('.bxs-preloader--progress--line')

    const tl = gsap.timeline({
        onComplete: () => {
            resolve(tl)
        },
        onReverseComplete: resolve
    })

    if (is_in) {
        tl
            .addLabel('start')
            .fromTo(el, {
                autoAlpha: is_in ? 0 : 1,
                y: is_in ? 120 : 0
            }, {
                autoAlpha: is_in ? 1 : 0,
                y: is_in ? 0 : 120,
                duration: 1,
                ease: 'expo.out'
            })
            .addLabel('step_2')
            /*
            .fromTo(title, {
                autoAlpha: is_in ? 0 : 1,
                y: is_in ? 40 : 0,
                skewX: is_in ? -10 : 0
            }, {
                autoAlpha: is_in ? 1 : 0,
                y: is_in ? 0 : 40,
                skewX: is_in ? 0 : -10,
                duration: 0.2,
                ease: 'expo.out'
            })
            */
            .addLabel('end')
    } else {
        tl
            .addLabel('start')
            /*
            .fromTo(title, {
                autoAlpha: is_in ? 0 : 1,
                y: is_in ? 40 : 0,
                skewX: is_in ? -10 : 0
            }, {
                autoAlpha: is_in ? 1 : 0,
                y: is_in ? 0 : 40,
                skewX: is_in ? 0 : -10,
                duration: 0.2,
                ease: 'expo.out'
            })
                */
            /*
            .to(progress_line, {
                transformOrigin: 'right bottom',
                scaleX: 0,
                duration: 0.2,
                ease: 'expo.out'
            })
            */
            .fromTo(el, {
                autoAlpha: is_in ? 0 : 1
            }, {
                autoAlpha: is_in ? 1 : 0,
                duration: 0.2,
                ease: 'expo.out'
            })
            .addLabel('end')
    }
}

const bigMenu = (el, direction, resolve) => {
    const is_in = direction === 'in'
    const items = [...el.querySelectorAll('[data-big-menu-item]')]

    const tl = gsap.timeline({
        onComplete: () => {
            resolve(tl)
        }
    })

    tl.addLabel('start')

    if (is_in) {
        tl
            .fromTo(el, {
                autoAlpha: 0
            }, {
                autoAlpha: 1,
                duration: 0.5,
                ease: 'expo.out'
            })
            .fromTo(items, {
                y: '200%'
            }, {
                y: 0,
                stagger: 0.05,
                ease: 'expo.out'
            })
    } else {
        tl
            .fromTo(el, {
                autoAlpha: 1
            }, {
                autoAlpha: 0,
                duration: 0.5,
                ease: 'expo.out'
            })
    }
}

// ------------------------------------------------------------------------------------------------------

[{
    key: 'fallback',
    timeline: fallback
}, {
    key: 'preloader',
    timeline: preloader
}, {
    key: 'big_menu',
    timeline: bigMenu
}].forEach((item) => {
    transitions.set(
        item.key,
        (ctx, direction) => new Promise(resolve => item.timeline(ctx, direction, resolve))
    )
})

export default transitions
