<template>
  <div class="single-project">
    <bxs-page-heading
      title="Progetti"
      subtitle="Crazy Week Project"
      src="https://staging.weplaycoop.it/wp-content/uploads/2024/10/servizi.jpg"
    >
    </bxs-page-heading>

    <section class="project-details">
      <bxs-layout>
        <bxs-row>
          <bxs-col cols="5">
            <div class="details-block">
              <div class="details-content">
                <strong>Cliente</strong><span>Isemprevivi</span>
                <strong>Categoria</strong><span>Live Show</span>
                <strong>Tags</strong><span>Musica Italiana</span>
                <strong>Date</strong><span>30/05/2024</span>
              </div>
            </div>
          </bxs-col>
          <bxs-col cols="7">
            <div class="project-text">
              <h2>Crazy Week Project</h2>
              <p>Giunti alla terza edizione della Crazy Week, ovvero la settimana dedicata alla cura della salute mentale della popolazione, We.Ensemble è stato ingaggiato per gestire la parte musicale dell’evento.</p>
              <p>La musica come ponte tra le persone, le generazioni, le diverse culture d’Italia, usata come grande comunicatrice di messaggi promotori di speranza e ascolto.</p>
              <p>Per questo We.Ensemble ha deciso di proporre un concerto con un programma eterogeno quale “Il Viaggio in Italia”.</p>
            </div>
          </bxs-col>
        </bxs-row>
      </bxs-layout>
    </section>

    <section class="project-gallery">
      <bxs-layout>
        <bxs-row>
          <div class="gallery-wrapper">
            <div class="main-photo">
              <bxs-figure src="/img/gallery-1.png"></bxs-figure>
            </div>
            <div class="subgallery">
              <bxs-figure src="/img/example-2.png" width="330" height="330"></bxs-figure>
              <bxs-figure src="/img/example.png" width="330" height="330"></bxs-figure>
              <bxs-figure src="/img/example-2.png" width="330" height="330"></bxs-figure>
            </div>
          </div>
        </bxs-row>
      </bxs-layout>
    </section>

    <section class="book-consulences back-to-projects">
      <bxs-layout>
        <bxs-row>
          <bxs-col cols="6">
            <bxs-figure src="/img/consulenza.jpg">
            </bxs-figure>
          </bxs-col>
          <bxs-col cols="6">
            <div class="consulences-content">
              <h3>Progetti</h3>
              <p>Il nostro team di esperti musicisti e professionisti è pronto a supportarti in ogni fase del tuo percorso.</p>
              <bxs-btn
              append-icon="arrow-right"
              to="/progetti"
              class="inverted"
              >
                Torna ai progetti
              </bxs-btn>
            </div>
          </bxs-col>
        </bxs-row>
      </bxs-layout>
    </section>
  </div>

</template>

<script>
export default {
    name: 'single-project',
  mounted () {
    this.$nextTick(this.start)
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mixins.scss';

.project-details{
  margin: 190px 0 0;

  .details-block{
    background: #CDFAFB;
    border-radius: 50px;
    padding: 30px 40px;

    .details-content{
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px 20px;
      padding: 20px;
    }
  }

  .project-text{
    margin-top: -85px;
    padding-left: 20px;

    p{
      font-size: 22px;
      line-height: 33px;
      margin-bottom: 40px;
      color: #4D5053;
    }
  }
}

.book-consulences{
  figure{
    border-radius: 50px;
  }

  h3{
    color: var(--color-black);
    font-size: 42px;
    line-height: 54px;
    margin-bottom: 20px;
  }

  p{
    font-size: 22px;
    line-height: 40px;
  }

  .consulences-content{
    padding-left: 40px;
  }
}
.project-gallery{
  margin-bottom: 100px;

  .main-photo{
    margin-bottom: 30px;

    figure{
      border-radius: 70px;
    }
  }

  .subgallery{
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    gap: 30px;

    figure{
      flex-grow: 1;
      border-radius: 40px;
    }
  }
}
</style>
