<template>
  <div
  ref="app"
  :class="['bxs-app', {
    'bxs-app-custom-scroll': is_custom_scroll,
    'bxs-loading': is_in_transition || is_loading || !is_preloaded,
    'bxs-disabled': is_in_transition
  }]">

    <transition
    @enter="enterPreloader"
    mode="out-in"
    appear>
      <bxs-preloader
      v-if="!is_preloaded"
      ref="preloader"
      :resources="resources"
      class="bxs-app--preloader">
        <template #default="{ progress_scale }">
          <div v-if="has_preloader">
            <bxs-logo max-width="150px" />
          <div
          ref="progress"
          class="bxs-preloader--progress">
            <div
            class="bxs-preloader--progress--line"
            :style="{
              transform: `scaleX(${progress_scale})`
            }"></div>
          </div>
          </div>
        </template>
      </bxs-preloader>
    </transition>

    <div
    v-if="is_preloaded"
    ref="wrapper"
    id="wrapper"
    class="bxs-app--wrapper"
    data-scroll-container>

      <bxs-navframe
      ref="navframe"
      :actived="navframe.is_actived"
      :hidden="navframe.is_hidden"
      class="bxs-app--navframe" />

      <main
      ref="main"
      class="bxs-app--main">
        <router-view
        ref="view"
        v-slot="{ Component }">
          <transition
          @enter="enterPage"
          @leave="leavePage"
          mode="out-in"
          appear>
            <component
            :is="Component"
            :key="$route.fullPath"
            ref="page"
            class="bxs-app--page" />
          </transition>
        </router-view>
      </main>

      <bxs-footer
      ref="footer"
      class="bxs-app--footer" />

      <transition
      mode="out-in"
      @enter="enterBigMenu"
      @leave="leaveBigMenu">
        <bxs-big-menu
        v-if="menu.on"
        ref="menu"
        class="bxs-app--big-menu" />
      </transition>

      <bxs-cookie />
    </div>

    <div
    v-if="$route.query.debug"
    class="debug-frameview">
      <div></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { fixVh } from '@/assets/libs/utils/dom'
import { sleep } from '@/assets/libs/utils/promise'
import transitions from '@/assets/libs/transitions'
import Lenis from '@studio-freight/lenis'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

import Preloader from '@/components/core/Preloader.vue'
import Navframe from '@/components/core/layout/Navframe.vue'
import FooterComp from '@/components/core/layout/Footer.vue'
import BigMenu from '@/components/core/layout/BigMenu.vue'
import Cookie from '@/components/core/Cookie.vue'

gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'App',
  components: {
    'bxs-preloader': Preloader,
    'bxs-navframe': Navframe,
    'bxs-footer': FooterComp,
    'bxs-big-menu': BigMenu,
    'bxs-cookie': Cookie
  },
  data () {
    return {
      scroll: null,
      navframe: {
        is_hidden: false,
        is_actived: true,
        height: 0
      },
      window: {
        height: 0,
        width: 0
      },
      menu: {
        on: false
      }
    }
  },
  computed: {
    ...mapState({
      has_preloader: state => state.has_preloader,
      is_first_enter: state => state.is_first_enter,
      is_preloaded: state => state.is_preloaded,
      is_loading: state => state.is_loading,
      is_in_transition: state => state.is_in_transitions,
      is_custom_scroll: state => state.is_custom_scroll,
      resources: state => state.resources
    })
  },
  head () {
    const has_dynamic_seo = !!this.page && !!this.page.seo
    // const has_robots = has_dynamic_seo && !!this.page.seo.data.robots
    const has_og_img = has_dynamic_seo && this.page.seo.data && this.page.seo.data.og_image && this.page.seo.data.og_image.length > 0

    return {
      title: has_dynamic_seo ? this.page.seo.data.title : '',
      meta: [
        { name: 'description', content: has_dynamic_seo ? this.page.seo.data.description : '' },
        { name: 'article:modified_time', content: has_dynamic_seo ? this.page.seo.data.article_modified_time : '' },
        // og
        { name: 'og:type', content: has_dynamic_seo ? this.page.seo.data.og_type : '' },
        { name: 'og:site_name', content: has_dynamic_seo ? this.page.seo.data.title : '' },
        { name: 'og:title', content: has_dynamic_seo ? this.page.seo.data.og_description : '' },
        { name: 'og:description', content: has_dynamic_seo ? this.page.seo.data.title : '' },
        { name: 'og:image', content: has_og_img ? this.page.seo.data.og_image[0].url : '' },
        { name: 'og:url', content: window.location.href },
        // twc
        { name: 'twitter:card', content: has_dynamic_seo ? this.page.seo.data.twitter_card : '' },
        { name: 'twitter:title', content: has_dynamic_seo ? this.page.seo.data.og_description : '' },
        { name: 'twitter:description', content: has_dynamic_seo ? this.page.seo.data.title : '' },
        { name: 'twitter:image', content: has_og_img ? this.page.seo.data.og_image[0].url : '' },
        // indexing
        { name: 'robots', content: has_dynamic_seo ? `${this.page.seo.data.robots.index},${this.page.seo.data.robots.follow}` : '' }
      ],
      script: [
        { type: 'application/ld+json', content: has_dynamic_seo ? JSON.stringify(this.page.seo.data.schema) : '' }
      ]
    }
  },
  created () {
    // console.log('Created', this)
    console.log('Created')

    this.$router.beforeEach((to, from, next) => {
      // console.log('Internal Router - beforeEach()')

      this.$store.commit('setIsFirstEnter', false)

      next()
    })
  },
  mounted () {
    console.log('Mounted()')

    this.$nextTick(this.start)
  },
  watch: {
    is_in_transition (newVal) {
      console.log('Watch is_in_transition', newVal)

      this[newVal ? 'pause' : 'play']()
    },
    'menu.on' (newVal) {
      console.log('Watch menu.on', newVal)

      this[newVal ? 'pause' : 'play']()
    }
  },
  methods: {
    // controls ------------------------------------------------------------------------------------------------------------
    async enterPreloader (el, next) {
      if (this.has_preloader) {
        await transitions.get('preloader')(el, 'in')
      }

      console.log('Preloading ...')
      await this.$refs.preloader.start()
      await sleep(10)

      if (this.has_preloader) {
        await transitions.get('preloader')(el, 'out')
      }

      await sleep(10)

      this.$store.commit('setIsPreloaded', true)
      this.$store.commit('setIsInTransition', true)

      next()
    },
    async start () {
      console.log('Start()')

      window.scrollTo(0, 0)
      document.body.classList.toggle('bxs-lock-scroll')

      this.resize()
      window.addEventListener('resize', this.resize)

      window.addEventListener('online', evt => this.$eventHub.emit('change-connection', evt))
      window.addEventListener('offline', evt => this.$eventHub.emit('change-connection', evt))

      // 404 CHECKER
      try {
          const page = await this.$store.dispatch('getPage', this.$route.path === '/' ? 'home' : this.$route.path)
          if (!page) throw new Error('route.not_found')
      } catch (err) {
        this.$router.push('/errors/404')
      }

      if (this.is_preloaded) {
        this.$store.commit('setIsInTransition', true)
      }
    },
    play () {
      this.$nextTick(() => {
        // console.log('Play()', this.$refs)
        console.log('Play()')

        if (this.scroll) {
          this.scroll.start()
        }

        if (!this.scroll && this.is_custom_scroll) {
          this.scroll = new Lenis({
            duration: 2.4,
            easing: x => Math.sin((x * Math.PI) / 2),
            lerp: 0.1,
            smooth: true,
            autoResize: true
          })

          // LENIS RAF
          const lenisRaf = (time) => {
            this.scroll.raf(time)
            requestAnimationFrame(lenisRaf)
          }
          requestAnimationFrame(lenisRaf)

          document.body.classList.toggle('bxs-lock-scroll')

          this.scroll.on('scroll', this.handlerScroll)
        } else {
          this.scroll.start()
        }

        this.resize()
        this.updateCtas(true)
      })
    },
    pause () {
      console.log('Pause()')

      if (this.scroll) {
        // Pauses the scroll
        this.scroll.stop()
      }
    },
    // handlers ------------------------------------------------------------------------------------------------------------
    handlerScroll (evt) {
      ScrollTrigger.update()

      // this.navframe.hidden = evt.scroll.y >= evt.limit.y - window.innerHeight
      this.navframe.actived = evt.scroll.y <= evt.limit.y - this.navframe.height
    },
    // fns ------------------------------------------------------------------------------------------------------------
    updateCtas (disabled = true) {
      const blanks = [...document.querySelectorAll(['a[target="_blank"]'])]
      blanks.forEach((el) => {
        if (!el.hasAttribute('rel')) el.setAttribute('rel', 'noopener')
      })

      const actions = [...document.querySelectorAll(['a button'])]
      actions.forEach((el) => {
        el.disabled = disabled
      })
    },
    resize () {
      if (this.$refs.navframe) this.navframe.height = this.$refs.navframe.$el.clientHeight

      this.window.height = window.innerHeight
      this.window.width = window.innerWidth

      fixVh()

      if (this.scroll) {
        // Compute internal sizes, it has to be used if autoResize option is false.
        this.scroll.resize()
      }

      // Ricalcola il posizionamento di tutti gli ScrollTriggers sulla pagina; questo in genere avviene automaticamente quando la finestra/lo scroller si ridimensiona ma puoi forzarlo chiamando ScrollTrigger.refresh()
      ScrollTrigger.refresh()
      // Controlla dove si trova la barra di scorrimento e aggiorna di conseguenza i valori di avanzamento e direzione di tutte le istanze ScrollTrigger, controlla l'animazione (se necessario) e attiva i callback appropriati
      ScrollTrigger.update()
    },
    // transitions ------------------------------------------------------------------------------------------------------------
    async enterPage (el, next) {
      console.log('EnterPage()')

      const els = [
        el,
        this.$refs.navframe.$el,
        this.$refs.footer.$el
      ]

      if (this.$route.meta.is_page && this.$store.state.page_enter !== this.$store.state.page_leave) {
        this.$store.dispatch('getPage', this.$route.path === '/' ? 'home' : this.$route.path)
      }

      await transitions.get('fallback')(els, 'in')
      this.updateCtas(false)

      this.$store.commit('setIsInTransition', false)
      this.$store.commit('setIsLoading', false)
      next()
    },
    leavePage (el, next) {
      console.log('LeavePage()')
      console.log(this.scroll)

      this.$store.commit('setIsInTransition', true)
      this.updateCtas(true)

      const els = [
        el,
        this.$refs.navframe.$el,
        this.$refs.footer.$el
      ]

      transitions.get('fallback')(els, 'out').then(next)
    },
    enterBigMenu (el, next) {
      return transitions.get('big_menu')(el, 'in').then(next)
    },
    leaveBigMenu (el, next) {
      return transitions.get('big_menu')(el, 'out').then(() => {
        next()
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/index.scss';
</style>
