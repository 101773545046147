<template>
    <div
    :class="classes"
    :style="measurable_styles">
        <slot />
    </div>
</template>

<script>
import { measurable } from '@/mixins'

export default {
    name: 'bxs-layout',
    mixins: [measurable],
    props: {
        fluid: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        classes () {
            return ['bxs-layout', {
                'bxs-layout-fluid': this.fluid
            }]
        }
    }
}
</script>

<style lang="scss">
.bxs-layout {
    position: relative;
    width: 100%;
    max-width: 1200px;

    margin-left: auto;
    margin-right: auto;

    padding-left: var(--size-layout);
    padding-right: var(--size-layout);

    &.bxs-layout-fluid {
        max-width: 100%;
    }
}
</style>
