<template>
  <div class="projects">
    <bxs-page-heading
      title="Progetti"
      subtitle="La musica perfetta per il vostro evento."
      src="https://staging.weplaycoop.it/wp-content/uploads/2024/10/projects.jpeg"
    >
    </bxs-page-heading>

    <section class="projects-focus">
      <bxs-layout>
        <bxs-row>
          <bxs-col cols="5">
            <h2>Il nostro team di esperti musicisti e professionisti è pronto a supportarti in ogni fase del tuo percorso.</h2>
          </bxs-col>
          <bxs-col cols="7">
            <p class="p-1">Il portfolio aggiornato di We.Ensemble, un insieme eterogeneo di eventi, live show, concerti e molto altro. </p>
            <p class="p-2">La profondità del nostro servizio al fine di soddisfare tutte le richieste musicali dell’azienda committente. Consulenza, arrangiamento, esecuzione musicale, feedback post evento.
            </p>
            <p class="p-3">Il nostro repertorio conta più di mille arrangiamenti originali, contenente musica pop italiana e internazionale.</p>
          </bxs-col>
        </bxs-row>
      </bxs-layout>
    </section>

    <section class="counters">
      <bxs-layout class="bxs-layout-fluid">
        <div class="counters-content">
          <div class="counter">
            <div class="number">
              <strong>100</strong><span>%</span>
            </div>
            <div class="number-info">
              <strong>Personalizzazione completa:</strong>
              <span>Adattabilità musicale garantita.</span>
            </div>
          </div>
          <div class="counter">
            <div class="number">
              <strong>50</strong><span>+</span>
            </div>
            <div class="number-info">
              <strong>Musicisti professionisti:</strong>
              <span>Roaster profondo, per ogni tipo di evento.</span>
            </div>
          </div>
          <div class="counter">
            <div class="number">
              <strong>100</strong><span>+</span>
            </div>
            <div class="number-info">
              <strong>Repertorio Canzoni:</strong>
              <span>Con arrangiamenti dedicati.</span>
            </div>
          </div>
          <div class="counter">
            <div class="number">
              <strong>200</strong><span>+</span>
            </div>
            <div class="number-info">
              <strong>Clienti soddisfatti:</strong>
              <span>La nostra musica, il vostro evento.</span>
            </div>
          </div>
        </div>
      </bxs-layout>
    </section>

    <section class="projects-list">
      <bxs-layout>
        <bxs-row>
          <bxs-col cols="12">
            <div class="single-project">
              <h3>BMW - Evento</h3>
              <p>L’Orchestra d’archi partecipa all’evento “Concorso d’eleganza 2022” presso Villa d’Este a Como.</p>
              <bxs-btn
              append-icon="arrow-right"
              to="/crazy-week-project"
              class="simple-link"
              >
                Scopri di più
              </bxs-btn>
              <div class="gallery">
                <bxs-figure src="/img/example.png" width="330" height="330"></bxs-figure>
                <bxs-figure src="/img/example-2.png" width="330" height="330"></bxs-figure>
                <bxs-figure src="/img/example.png" width="330" height="330"></bxs-figure>
              </div>
            </div>
          </bxs-col>
        </bxs-row>
        <bxs-row>
          <bxs-col cols="12">
            <div class="single-project odd">
              <h3>BMW - Evento</h3>
              <p>L’Orchestra d’archi partecipa all’evento “Concorso d’eleganza 2022” presso Villa d’Este a Como.</p>
              <bxs-btn
              append-icon="arrow-right"
              to="/crazy-week-project"
              class="simple-link"
              >
                Scopri di più
              </bxs-btn>
              <div class="gallery">
                <bxs-figure src="/img/example.png" width="330" height="330"></bxs-figure>
                <bxs-figure src="/img/example-2.png" width="330" height="330"></bxs-figure>
                <bxs-figure src="/img/example.png" width="330" height="330"></bxs-figure>
              </div>
            </div>
          </bxs-col>
        </bxs-row>
      </bxs-layout>
    </section>

    <bxs-our-customers style="margin-bottom: 130px" />

    <section class="book-consulences">
      <bxs-layout>
        <bxs-row>
          <bxs-col cols="6">
            <bxs-figure src="/img/consulenza.jpg">
            </bxs-figure>
          </bxs-col>
          <bxs-col cols="6">
            <div class="consulences-content">
              <h3>Prenota una consulenza per il tuo evento.</h3>
              <p>I nostri professionisti sono pronti ad accogliere le tue richieste musicali per elevare il prestigio della tua iniziativa.</p>
              <bxs-btn
              append-icon="arrow-right"
              to="/contatti"
              class="inverted"
              >
                Contattaci
              </bxs-btn>
            </div>
          </bxs-col>
        </bxs-row>
      </bxs-layout>
    </section>

  </div>
</template>

<script>
import { gsap } from 'gsap'

export default {
  name: 'projects',
  mounted () {
    this.$nextTick(this.start)
  },
  methods: {
    animateNumbers(elements, count, wrapper) {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: wrapper,
          start: 'top 70',
          toggleActions: 'play none none none'
        }
      })

      elements.forEach((element, index) => {
        const delay = index * 0.5

        if (element.getAttribute('data-number-type') === 'true') {
          const counter = { value: 0 }
          tl
          .to(counter, {
            value: parseInt(element.getAttribute('data-number-value')),
            duration: 1,
            ease: 'power1.out',
            onUpdate: function() {
              element.innerHTML = Math.floor(counter.value)
            }
          }, delay)
        } else {
          tl
          .to(element, {
            opacity: 1,
            duration: 1,
            ease: 'power1.out'
          }, delay)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mixins.scss';

.projects-focus{
  margin-top: 50px;
  margin-bottom: 50px;

  h2{
    color: var(--color-black);
    font-weight: 800;
    font-size: 44px;
    line-height: 64px;
  }

  p{
    font-size: 24px;
    line-height: 38px;
    padding-left: 24px;
  }

  .p-1{
    font-weight: bold;
    color: var(--color-primary);
    margin-top: 20px;
  }

  .p-2{
    margin-top: 46px;
  }

  .p-3{
    margin-top: 46px;
  }
}

.counters{
  background-color: #E6FDFD;
  padding: 120px 0 120px;

  .counters-content{
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;

    .counter{
      width: 25%;
    }

    .number{
      font-weight: 600;
      font-size: 80px;
      line-height: 100px;
      color: #024345;
    }

    .number-info{
      text-align: center;
      font-size: 20px;
      line-height: 30px;
      display: flex;
      flex-direction: column;
    }
  }
}

.book-consulences{
  figure{
    border-radius: 50px;
  }

  h3{
    color: var(--color-black);
    font-size: 42px;
    line-height: 54px;
    margin-bottom: 20px;
  }

  p{
    font-size: 22px;
    line-height: 40px;
  }

  .consulences-content{
    padding-left: 40px;
  }
}

.projects-list{
  margin-top: 100px;

  .single-project{
    background:transparent url('@/assets/img/bg-project.png') no-repeat center center;
    border-radius: 60px;
    padding: 60px 40px;
    color: white;
    margin-bottom: 100px;

    &.odd{
      background: #CDFAFB4D;
      color: var(--color-black);
    }

    h3{
      font-size: 35px;
      font-weight: 700;
      margin-bottom: 30px;
    }

    p{
      font-size: 22px;
      margin-bottom: 20px;
    }

    .bxs-btn{
      margin-bottom: 20px;
    }
  }

  .gallery{
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    align-content: space-between;

    figure{
      border-radius: 40px;
      margin: auto;
    }

    *{
      background: transparent;
    }
  }
}
</style>
