const r = num => Math.round((num + Number.EPSILON) * 100) / 100

export function fixSvg (svg) {
    const box = svg.getBBox()
    const viewBox = [
        r(box.x),
        r(box.y),
        r(box.width),
        r(box.height)
    ].join(' ')

    svg.setAttribute('viewBox', viewBox)
    svg.removeAttribute('width')
    svg.removeAttribute('height')
}

export function isInViewport(el) {
    const rect = el.getBoundingClientRect()
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
}