import Http from './Http'
import Emitter from './Eventer'

const emitter = new Emitter()

class Wp {
    constructor(base_url) {
        this.emitter = emitter
        this.http = new Http(
            { base_url: base_url || process.env.VUE_APP_API },
            this.emitter
        )
    }

    on(name, cb) {
        const isArr = Array.isArray(name)
        if (isArr) return name.forEach(evt => this.tools.emitter.on(evt, cb))
        return this.tools.emitter.on(name, cb)
    }

    off(name) {
        const isArr = Array.isArray(name)
        if (isArr) return name.forEach(evt => this.tools.emitter.off(evt))
        this.tools.emitter.off(name)
    }

    // https://customer.com/wp-json/v1/menu/2
    getMenu (id = 2) {
        return this.http.get('/v1/menu/' + id)
    }

    // https://customer.com/wp-json/v1/menu/footer
    getFooter () {
        return this.http.get('/v1/menu/footer')
    }

    // https://customer.com/wp-json/wp/v2/pages?slug=home
    getPages(limit = 100) {
      console.log('GET PAGESSSS')
        return this.http.get('/wp/v2/pages', {
            per_page: limit
        })
    }

    // https://customer.com/wp-json/wp/v2/pages?slug=home
    getPage (slug) {
        console.log('GET PAGE')
        return this.http.get('/wp/v2/pages', { slug })
    }

    // https://customer.com/wp-json/v1/slides
    getSlides () {
        return this.http.get('/v1/slides')
    }

    // https://customer.com/wp-json/v1/categories?taxonomy=work_category
    getCategories (taxonomy, parent) {
        return this.http.get('/v1/categories', { taxonomy, parent })
    }

    // https://customer.com/wp-json/v1/posts?type=service&limit=-1
    getPosts (q = {}) {
        return this.http.get('/wp/v2/posts', q)
    }

    // https://customer.com/wp-json/v1/posts?type=service&limit=-1
    getLatestPosts (q = {}) {
        return this.http.get('/wp/v2/posts?limit=4&sort=desc', q)
    }

    getPost (slug) {
        return this.http.get('/v1/posts/' + slug)
    }
}

export default Wp
